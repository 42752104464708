import React from "react";
import { SqftRange } from "../../../helpers/SearchEngineHelpers";

export default function AreaSqft(props) {
  const [sqftMin, sqftMax] = SqftRange(props);

  const renderSqft = () => {
    if (
      parseInt(props.selectedSqft.min) === 0 &&
      props.selectedSqft.max === "Any"
    ) {
      return "Any";
    } else {
      return (
        parseInt(props.selectedSqft.min).toLocaleString("en-US") +
        " to " +
        (props.selectedSqft.max === "Any"
          ? "Any"
          : parseInt(props.selectedSqft.max).toLocaleString("en-US"))
      );
    }
  };

  return (
    <>
      <div className="dropdown myinputdropdownmother">
        <button
          className="btn btn-light dropdown-toggle myinput myinputdropdown"
          aria-expanded="false"
          data-bs-toggle="dropdown"
          type="button"
          data-bs-display="static"
          aria-label="Any"
        >
          {renderSqft()}
        </button>
        <div className="dropdown-menu">
          <div>
            <label className="form-label text-center d-block mb-3">
              Area Sqft
            </label>
            <div className="filterlistbuttons">
              <div className="d-flex gap-1 mb-3">
                <div className="w-100 rangeleftrighttdiv">
                  <p className="text-center gilroybold m-0">Min</p>
                </div>
                <div className="w-100 rangeleftrighttdiv">
                  <p className="text-center gilroybold m-0">Max</p>
                </div>
              </div>
              <div className="d-flex gap-1 mb-3">
                <div className="w-100 rangeleftrighttdiv">
                  <input
                    className="form-control"
                    type="number"
                    id="sqftMin"
                    placeholder={props.selectedSqft.min}
                    onChange={props.handleMinManual}
                  />
                </div>
                <div className="w-100 rangeleftrighttdiv">
                  <input
                    className="form-control"
                    type="number"
                    id="sqftMax"
                    placeholder={props.selectedSqft.max}
                    onChange={props.handleMaxManual}
                  />
                </div>
              </div>
              <div className="d-flex gap-1">
                <div className="rangeleftrighttdiv makeitscrollable">
                  {sqftMin.map((sqft, index) => {
                    return <div key={index}>{sqft}</div>;
                  })}
                </div>
                <div className="rangeleftrighttdiv makeitscrollable">
                  {sqftMax.map((sqft, index) => {
                    return <div key={index}>{sqft}</div>;
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
