import React, { useEffect, useRef, useState } from "react";
import { bedRooms } from "../../../dictionaries/types";

export default function Bedrooms(bdrObj) {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);

  const renderHeader = () => {
    let header;
    if (bdrObj.selectedBedrooms.length > 0) {
      let headerLong = bdrObj.selectedBedrooms.toString();
      header =
        headerLong.length > 15
          ? headerLong.substring(0, 13) + "..."
          : headerLong;
    } else {
      header = "Any";
    }
    return header;
  };

  const handleCheckboxClick = (event) => {
    bdrObj.handleCheck(event);
    setDropdownOpen(false);
  };

  return (
    <>
      <div className="dropdown myinputdropdownmother">
        <button
          className="btn btn-light dropdown-toggle myinput myinputdropdown"
          id="bedroomdropdown"
          aria-expanded={dropdownOpen}
          aria-label="Any"
          data-bs-toggle="dropdown"
          type="button"
          data-bs-display="static"
          onClick={() => setDropdownOpen((prevState) => !prevState)}
        >
          {renderHeader()}
        </button>
        <div className="dropdown-menu" ref={dropdownRef}>
          <div>
            <label className="form-label mainlabel">Bedrooms</label>
            <div className="radiolist monolabel">
              {bedRooms.map((b, index) => {
                let placeHolder = b;
                b === "studio" ? (placeHolder = "0") : (placeHolder = b);
                let checked = bdrObj.selectedBedrooms.includes(placeHolder)
                  ? true
                  : false;
                return (
                  <div className="form-check" key={index}>
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id={"bdr-" + b}
                      name="bedroom"
                      value={b}
                      defaultChecked={checked}
                      onClick={handleCheckboxClick}
                    />
                    <label
                      className="form-check-label"
                      htmlFor={"bdr-" + b.replace(/\+/g, "")}
                    >
                      {b}
                    </label>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
