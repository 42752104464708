export const isSale = (query) => {
    if (window.location.pathname.includes("sale")) {
        return true;
    }
    else if (window.location.pathname.includes("rent")) {
        return false;
    } else if (query.get("businessType") === "sale") {
        return true;
    }
    else {
        return false;
    }
}

export const PriceRange = (priceObj) => {

    const pricesMin = [];
    const pricesMax = [];

    const currentMin = (priceObj.selectedPrice.min) ? priceObj.selectedPrice.min : 0;
    const currentMax = (priceObj.selectedPrice.max && priceObj.selectedPrice.max !== "Any") ? priceObj.selectedPrice.max : 250000000;

    pricesMin.push(<button className="rangefilterbtn" id={0} type="button" onClick={priceObj.handlePriceMin}>0</button>);
    pricesMax.push(<button className="rangefilterbtn" id={"Any"} type="button" onClick={priceObj.handlePriceMax}>Any</button>);

    for (let index = 0; index < 200000001;) {

        if (index < 300000) {
            index = index + 25000;
        } else if (index < 800000) {
            index = index + 50000;
        } else if (index < 1000000) {
            index = index + 100000;
        } else if (index < 10000000) {
            index = index + 500000;
        } else if (index < 50000000) {
            index = index + 5000000;
        } else if (index < 100000000) {
            index = index + 10000000;
        } else {
            index = index + 50000000;
        }
        if (currentMax > index) {
            pricesMin.push(<button className="rangefilterbtn" id={index} type="button" onClick={priceObj.handlePriceMin}>{index.toLocaleString('en-US')}</button>);
        }

        if (currentMin < index) {
            pricesMax.push(<button className="rangefilterbtn" id={index} type="button" onClick={priceObj.handlePriceMax}>{index.toLocaleString('en-US')}</button>);
        }

    }

    return [pricesMin, pricesMax];
}


export const SqftRange = (sqftObj) => {

    const sqftMin = [];
    const sqftMax = [];

    const currentMin = (sqftObj.selectedSqft.min) ? sqftObj.selectedSqft.min : 0;
    const currentMax = (sqftObj.selectedSqft.max && sqftObj.selectedSqft.max !== "Any") ? sqftObj.selectedSqft.max : 35000;

    sqftMin.push(<button className="rangefilterbtn" id={0} type="button" onClick={sqftObj.handleSqftMin}>0</button>);
    sqftMax.push(<button className="rangefilterbtn" id={"Any"} type="button" onClick={sqftObj.handleSqftMax}>Any</button>);

    for (let index = 0; index < 30001;) {

        if (index < 8000) {
            index = index + 500;
        } else if (index < 15000) {
            index = index + 1000;
        } else if (index < 25000) {
            index = index + 2500;
        } else {
            index = index + 5000;
        }

        if (currentMax > index) {
            sqftMin.push(<button className="rangefilterbtn" type="button" id={index} onClick={sqftObj.handleSqftMin}>{index.toLocaleString('en-US')}</button>);
        }

        if (currentMin < index) {
            sqftMax.push(<button className="rangefilterbtn" type="button" id={index} onClick={sqftObj.handleSqftMax}>{index.toLocaleString('en-US')}</button>);
        }
    }

    return [sqftMin, sqftMax];
}