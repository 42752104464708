import { global } from "@/config/global";
import { useAmp } from "next/amp";
import Head from "next/head";
import { useRouter } from "next/router";
import React from "react";

export default function MetaTags(props) {
  const router = useRouter();
  const path = router.asPath.split("?")[0];
  const cannonical = global.self + router.asPath;
  let before = props.props ? props.props : props;
  const isAmp = useAmp();

  function generateBreadcrumbs(path) {
    const parts = path.split("/").filter(Boolean);
    const breadcrumbs = parts.map((part, i) => {
      return {
        path: "/" + parts.slice(0, i + 1).join("/"),
        label: part.charAt(0).toUpperCase() + part.slice(1),
      };
    });

    return [{ path: "/", label: "Home" }, ...breadcrumbs];
  }

  const breadcrumbs = generateBreadcrumbs(router.asPath);

  const metaData = before.meta;

  const createWebsiteJSONObject = () => {
    return {
      "@context": "https://schema.org/",
      "@type": "WebSite",
      "name": "Zeekeez",
      "url": "https://zeekeez.com/",
      "sameAs": "https://zeekeez.com/",
      "description": "#1 UAE Property Portal, Zeekeez is the best way to find your dream home in the United Arab Emirates. Zeekeez displays thousands of exclusive listings in Dubai, Abu Dhabi, Sharjah, Ras Al Khaima: villas, apartments, luxury properties, penthouses, duplexes, studios, and more. Zeekeez covers all UAE Real Estate market and works with adorable UAE's agencies, the best professionals to ensure the best customer experience.",
      "potentialAction": {
        "@type": "SearchAction",
        "target": "https:\/\/zeekeez.com\/en\/sale\/properties-for-sale?q={search_term_string}",
        "query-input": "required name=search_term_string",
      },
      "about": {
        "@type": "Thing",
        "sameAs": [
          "https://zeekeez.com/",
          "https://www.instagram.com/zeekeez_official/",
          "https://www.facebook.com/zeekeezofficial/",
          "https://www.linkedin.com/company/zeekeez/"
        ],
        "url": "https://zeekeez.com/"
      },
      "author": {
        "@type": "Organization",
        "logo": "https://zeekeez.com/assets/img/zeekeezlogo.svg",
        "name": "Zeekeez",
        "url": "https://zeekeez.com/"
      },
      "copyrightYear": "2020",
      "dateCreated": "2020-01-01T00:00:00.000Z",
      "funder": {
        "@type": "Person",
        "name": "Karim Nemouchi"
      },
      "inLanguage": "English"
    };
  };

  const createOrganizationJSONObject = () => {
    return {
      "@context": "https://schema.org",
      "@type": "Organization",
      "name": "Zeekeez",
      "description": "#1 UAE Property Portal, Zeekeez is the best way to find your dream home in the United Arab Emirates. Zeekeez displays thousands of exclusive listings in Dubai, Abu Dhabi, Sharjah, Ras Al Khaima: villas, apartments, luxury properties, penthouses, duplexes, studios, and more. Zeekeez covers all UAE Real Estate market and works with adorable agencies, the best professionals to ensure the best customer experience.",
      "url": "https://zeekeez.com/",
      "sameAs": ["https://www.linkedin.com/company/zeekeez/", "https://www.facebook.com/zeekeezofficial", "https://www.instagram.com/zeekeez_official/"],
      "telephone": "+97145781335",
      "foundingDate": "2020",
      "founder": [
        { "@type": "Person", "name": "Karim Nemouchi" },
      ],
      "employee": { "@type": "Person", "name": "Karim Nemouchi", "jobTitle": "CEO" },
      "numberOfEmployees": { "@type": "QuantitativeValue", "minValue": "25", "maxValue": "100" },
      "image": { "@type": "ImageObject", "url": "/assets/img/zeekeezlogo.svg" },
      "logo": { "@type": "ImageObject", "url": "/assets/img/zeekeezlogo.svg" },
      "address": {
        "@type": "PostalAddress",
        "streetAddress": "Office 11, Building - 03, DIFC",
        "addressLocality": "Dubai",
        "addressCountry": { "@type": "Country", "name": "UAE" },
      }
    };
  };

  const createBreadCrumbsJSONObject = () => {
    return {
      "@context": "https://schema.org",
      "@type": "BreadcrumbList",
      itemListElement: breadcrumbs.map((breadcrumb, i) => ({
        "@type": "ListItem",
        position: i + 1,
        name: breadcrumb.label,
        item: `https://zeekeez.com${breadcrumb.path}`,
      })),
    };
  };

  const handleTags = () => {
    const indexFollow = props.properties
      ? props.properties.stats.total !== 0
        ? "INDEX, FOLLOW"
        : "NOINDEX, FOLLOW"
      : "INDEX, FOLLOW";

    const titlePageGenearator = () => {
      let title = "";
      if (router.query.page) {
        title = title + "Page " + router.query.page + " - ";
      }
      return title + metaData?.title;
    };
    return (
      <Head>
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta name="og:title" content={metaData?.title} />
        <meta name="og:description" content={metaData?.metadescription} />
        <meta name="description" content={metaData?.metadescription} />
        <meta name="keywords" content={metaData?.keywords} />

        <meta name="Robots" content={indexFollow} />
        <meta name="YahooSeeker" content={indexFollow} />
        <meta name="msnbot" content={indexFollow} />
        <meta name="googlebot" content={indexFollow} />
        {!props.search && (
          <>
            <script
              type="application/ld+json"
              dangerouslySetInnerHTML={{ __html: JSON.stringify(createWebsiteJSONObject()) }}
            />
            <script
              type="application/ld+json"
              dangerouslySetInnerHTML={{ __html: JSON.stringify(createOrganizationJSONObject()) }}
            />
            <script
              type="application/ld+json"
              dangerouslySetInnerHTML={{ __html: JSON.stringify(createBreadCrumbsJSONObject()) }}
            />
          </>
        )}
        <title>{titlePageGenearator()}</title>

        {!isAmp && <link rel="canonical" href={cannonical} key="canonical" />}
      </Head>
    );
  };

  try {
    if (props.meta) {
      let prop = props.meta;

      return (
        <Head>
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1.0"
          />
          <meta name="og:title" content={prop.title} />
          <meta name="og:description" content={prop.metadescription} />
          <meta name="description" content={prop.metadescription} />
          <meta name="keywords" content={prop.keywords} />

          <meta name="Robots" content={"INDEX, FOLLOW"} />
          <meta name="YahooSeeker" content={"INDEX, FOLLOW"} />
          <meta name="msnbot" content={"INDEX, FOLLOW"} />
          <meta name="googlebot" content={"INDEX, FOLLOW"} />

          <title>{prop.title}</title>
          {!isAmp && <link rel="canonical" href={cannonical} key="canonical" />}
          {!props.search && (
            <>
              <script
                type="application/ld+json"
                dangerouslySetInnerHTML={{ __html: JSON.stringify(createWebsiteJSONObject()) }}
              />
              <script
                type="application/ld+json"
                dangerouslySetInnerHTML={{ __html: JSON.stringify(createOrganizationJSONObject()) }}
              />
              <script
                type="application/ld+json"
                dangerouslySetInnerHTML={{ __html: JSON.stringify(createBreadCrumbsJSONObject()) }}
              />
            </>
          )}
        </Head>
      );
    } else {
      return handleTags();
    }
  } catch (error) {
    return handleTags();
  }
}
