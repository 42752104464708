import React, { useEffect, useState } from "react";
import _debounce from 'lodash/debounce';

import { capitalizeAllFirsts } from "@/helpers/globalHelpers";
import { searchLocation } from "@/helpers/locationHelpers";

const debounceTimeMs = 100;

export default function Locations(locObj) {
  const [locationfilter, setLocationFilter] = useState("");
  const [locationFilterList, setLocationFilterList] = useState([]);
  const [loadLocations, setLoadLocations] = useState(false);
  const [text, setText] = useState("");
  const [id, setId] = useState(null);

  const debouncedSetLocationFilterList = _debounce(async (filter) => {
    setLocationFilterList(await filterLocations(filter));
  }, debounceTimeMs);

  useEffect(() => {
    setId("location-menu" + Math.random());
  }, []);

  useEffect(() => {
    debouncedSetLocationFilterList(locationfilter);
    return () => {
      debouncedSetLocationFilterList.cancel();
    };
  }, [locationfilter]);

  useEffect(() => {
    debouncedSetLocationFilterList(locationfilter);
    return () => {
      debouncedSetLocationFilterList.cancel();
    };
  }, [locObj.selectedLocations]);

  const filterLocations = async (searchText) => {
    try {
      const locations = await searchLocation({name: searchText}, null);
      return locations.locations;
    } catch (e) {
      console.log("search error:", e);
    }
  };

  const renderFirstBadge = () => {
    return locObj.selectedLocations?.length >= 1 ? (
      <span
        className={"badge location-badge firstCitybadge show-it-now"}
        id="firstCitybadge"
      >
        <span id="first-city">
          {capitalizeAllFirsts(
            locObj.selectedLocations[0].replaceAll("-", " ")
          )}
        </span>
        <i
          className="fas fa-times closeBtn"
          id={0}
          onClick={locObj.removeLocation}
        ></i>
      </span>
    ) : (
      ""
    );
  };

  const renderMoreBadge = () => {
    return locObj.selectedLocations?.length > 1 ? (
      <div className="dropup myinputdropdownmother">
        <button
          className="btn btn-primary dropdown-toggle more-locations-badge  show-it-now"
          aria-expanded="false"
          data-bs-toggle="dropdown"
          id="more-locations-badge"
          type="button"
          aria-label="More locations"
        >
          <span id="morenumber">+{locObj.selectedLocations.length - 1}</span>
          <span>&nbsp;More</span>
        </button>
        <div
          className="dropdown-menu location-menu-in"
          style={{ zIndex: 99999 }}
        >
          {locObj.selectedLocations.map((loc, index) => {
            if (locObj.selectedLocations.indexOf(loc) > 0) {
              return (
                <span className="location-badge" key={index}>
                  {capitalizeAllFirsts(loc.replaceAll("-", " "))}
                  <i
                    className="fas fa-times closeBtn"
                    id={locObj.selectedLocations.indexOf(loc)}
                    onClick={locObj.removeLocation}
                  ></i>
                </span>
              );
            }
          })}
        </div>
      </div>
    ) : (
      ""
    );
  };

  const handleSelectLocation = (e) => {
    locObj.addLocation(e);
    setText("");
  };

  return (
    <>
      <div className="inputwithicondiv location-input">
        <div className="location-badges">
          {renderMoreBadge()}
          {renderFirstBadge()}
        </div>
        <input
          className="form-control myinput"
          type="text"
          id="location-input"
          placeholder="City, community or building"
          value={text}
          onFocus={() => {
            setLoadLocations(true);
            if (id) {
              document.getElementById(id).classList.add("show-it-now");
            }
          }}
          onChange={(e) => {
            setLocationFilter(e.target.value.toLowerCase());
            setText(e.target.value);
          }}
          onBlur={() => {
            setTimeout(() => {
              setLoadLocations(false);
            }, 200);
          }}
        />
        <i className="la la-map-marker" />
        <div id={id} className={"location-menu"}>
          {loadLocations &&
            locationFilterList.map((loc, index) => {
              return (
                <button
                  aria-label="Location"
                  key={loc.id}
                  type="button"
                  id={loc.slug}
                  onClick={handleSelectLocation}
                >
                  {loc.name}
                </button>
              );
            })}
        </div>
      </div>
    </>
  );
}
