import React, { useEffect, useState } from "react";
import { PriceRange } from "../../../helpers/SearchEngineHelpers";

export default function Price(props) {
  const [pricesMin, pricesMax] = PriceRange(props);

  const renderPrice = () => {
    if (
      parseInt(props.selectedPrice.min) === 0 &&
      props.selectedPrice.max === "Any"
    ) {
      return "Any";
    } else {
      return (
        parseInt(props.selectedPrice.min).toLocaleString("en-US") +
        " to " +
        (props.selectedPrice.max === "Any"
          ? "Any"
          : parseInt(props.selectedPrice.max).toLocaleString("en-US"))
      );
    }
  };

  return (
    <>
      <div className="dropdown myinputdropdownmother">
        <button
          className="btn btn-light dropdown-toggle myinput myinputdropdown"
          aria-expanded="false"
          data-bs-toggle="dropdown"
          type="button"
          data-bs-display="static"
          aria-label="Any"
        >
          {renderPrice()}
        </button>
        <div className="dropdown-menu dropdown-menu-start">
          <div>
            <label className="form-label text-center d-block mb-3">
              Price Range
            </label>
            <div className="filterlistbuttons">
              <div className="d-flex gap-1 mb-3">
                <div className="w-100 rangeleftrighttdiv">
                  <p className="text-center gilroybold m-0">Min</p>
                </div>
                <div className="w-100 rangeleftrighttdiv">
                  <p className="text-center gilroybold m-0">Max</p>
                </div>
              </div>
              <div className="d-flex gap-1 mb-3">
                <div className="w-100 rangeleftrighttdiv">
                  <input
                    className="form-control"
                    type="number"
                    id="priceMin"
                    placeholder={props.selectedPrice.min}
                    onChange={props.handleMinManual}
                  />
                </div>
                <div className="w-100 rangeleftrighttdiv">
                  <input
                    className="form-control"
                    type="number"
                    id="priceMax"
                    placeholder={props.selectedPrice.max}
                    onChange={props.handleMaxManual}
                  />
                </div>
              </div>
              <div id="filterbuttons" className="d-flex gap-1 filterbuttons">
                <div className="rangeleftrighttdiv makeitscrollable">
                  {pricesMin.map((price, index) => (
                    <React.Fragment key={index}>{price}</React.Fragment>
                  ))}
                </div>
                <div className="rangeleftrighttdiv makeitscrollable">
                  {pricesMax.map((price, index) => (
                    <React.Fragment key={index}>{price}</React.Fragment>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
