import React, { useEffect, useState } from "react";
import { apiGet } from "@/helpers/apiHelpers"; // Assurez-vous que cette importation est correcte

export default function Type(props) {
  const [propertyTypes, setPropertyTypes] = useState([]);
  const [commercialTypes, setCommercialTypes] = useState({
    left: [],
    right: [],
  });
  const [residentialTypes, setResidentialTypes] = useState({
    left: [],
    right: [],
  });

  // Fetching property types
  useEffect(() => {
    apiGet("/sitemap/v1/superAdmin/sitemap/property-types")
      .then((res) => {
        if (res.propertyTypes) {
          setPropertyTypes(res.propertyTypes);
        }
      })
      .catch((error) =>
        console.error(
          "Erreur lors de la récupération des types de propriété:",
          error
        )
      );
  }, []);

  // Parsing residential types
  useEffect(() => {
    const left = [],
      right = [];
    propertyTypes
      .filter((type) => type.residential === true)
      .forEach((type, index) => {
        (index % 2 === 0 ? left : right).push(type);
      });
    setResidentialTypes({ left, right });
  }, [propertyTypes]);

  // Parsing commercial types
  useEffect(() => {
    const left = [],
      right = [];
    propertyTypes
      .filter((type) => type.commercial === true)
      .forEach((type, index) => {
        (index % 2 === 0 ? left : right).push(type);
      });
    setCommercialTypes({ left, right });
  }, [propertyTypes]);

  // Handle checkbox click
  const handleTypeClick = (event) => {
    if (props.handleTypeClick) {
      props.handleTypeClick(event);
    }
  };

  // Render a single checkbox
  const renderType = (type, index) => {
    const isChecked = props.selectedTypes?.includes(type.code);
    return (
      <div className="form-check" key={index}>
        <input
          className="form-check-input"
          type="checkbox"
          id={type.code}
          name={type.code}
          checked={isChecked}
          onChange={handleTypeClick}
        />
        <label className="form-check-label" htmlFor={type.code}>
          {type.value}
        </label>
      </div>
    );
  };

  // Render header based on selected types
  const renderTypeHeader = () => {
    try {
      const selectedTypes = props.selectedTypes
        ? decodeURIComponent(props.selectedTypes).split(",")
        : [];
      const translated = selectedTypes
        .map((s) => {
          const foundType = propertyTypes.find((t) => t.code === s);
          return foundType ? foundType.value : "";
        })
        .filter(Boolean);

      const headerLong = translated.join(", ") || "Any";
      return headerLong.length > 45
        ? `${headerLong.substring(0, 43)}...`
        : headerLong;
    } catch (error) {
      console.error("Une erreur s'est produite dans renderTypeHeader:", error);
      return "Any";
    }
  };

  // Main render
  return (
    <div className="dropdown myinputdropdownmother" id="typeDropdown">
      <button
        id={"typeInput"}
        className="btn btn-light dropdown-toggle myinput myinputdropdown"
        aria-expanded="false"
        data-bs-toggle="dropdown"
        type="button"
        data-bs-display="static"
        aria-label="Any"
      >
        {renderTypeHeader()}
      </button>
      <div className="dropdown-menu dropdown-menu-start w-100">
        <div id="typeTabs" className="mytabs">
          <ul className="nav nav-tabs nav-fill" role="tablist">
            <li className="nav-item" role="presentation">
              <a
                title={"Residential"}
                className="nav-link active"
                role="tab"
                data-bs-toggle="tab"
                href="#tab-1"
                onClick={(event) => event.stopPropagation()}
              >
                Residential
              </a>
            </li>
            <li className="nav-item" role="presentation">
              <a
                title={"Commercial"}
                className="nav-link"
                role="tab"
                data-bs-toggle="tab"
                href="#tab-2"
                onClick={(event) => event.stopPropagation()}
              >
                Commercial
              </a>
            </li>
          </ul>
          <div className="tab-content">
            <div className="tab-pane active" role="tabpanel" id="tab-1">
              <div className="twopartdropdowndiv">
                <div className="radiolist monolabel">
                  {residentialTypes.left.map((type, index) =>
                    renderType(type, index)
                  )}
                </div>
                <div className="radiolist monolabel">
                  {residentialTypes.right.map((type, index) =>
                    renderType(type, index)
                  )}
                </div>
              </div>
            </div>
            <div className="tab-pane" role="tabpanel" id="tab-2">
              <div className="twopartdropdowndiv">
                <div className="radiolist monolabel">
                  {commercialTypes.left.map((type, index) =>
                    renderType(type, index)
                  )}
                </div>
                <div className="radiolist monolabel">
                  {commercialTypes.right.map((type, index) =>
                    renderType(type, index)
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
